import React from 'react'
import { Button } from 'react-bootstrap'

const ButtonFields = ({data}) => {
  const btn = (data) => {
    if('fields' in data) {
      return <Button href={data.fields.url['en-US'] } className={`btn btn-${data.fields.appointment['en-US'] ? 'secondary' : 'primary'} ${data.fields.title['en-US'] ? data.fields.title['en-US'] : ''}`} target="_blank">{ data.fields.title['en-US'] }</Button>
    }
    else {
      { return data.map((item, count) =>
        <Button href={item.url} className={`btn btn-${item.appointment ? 'secondary' : 'primary'} ${item.title ? item.title : ''}`} key={count} target="_blank">{ item.title }</Button>
       )
      }
    }
  }

  return (
    <div className="text-center button-holder">
      {btn(data) }
    </div>
  )
}

export default ButtonFields