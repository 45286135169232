import React, { Fragment } from 'react'
import { Card } from 'react-bootstrap'
import LoadMore from '../components/loadMore'

const Testimonials = ({data}) => {
  return (
    <Fragment>
      <LoadMore data={data} showMore={6} showItems={6}>
        {({items, showItems, currentItems}) => (
          <Fragment>
            { currentItems.map((item, key) => 
              <Card className="testimonial" key={'testimonials_' + key}>
                <Card.Title className="card-title">{ item.title }</Card.Title>
                <Card.Body>
                  <Card.Text>{ item.quote.quote }</Card.Text>
                  <Card.Link href="#">{ item.name }</Card.Link>
                  <Card.Link href="#">{ item.occupation }</Card.Link>
                  <Card.Link href="#">{ item.location }</Card.Link>
                </Card.Body>
              </Card>
            )}
          </Fragment>     
        )}
      </LoadMore>
    </Fragment>
  ) 
}

export default Testimonials