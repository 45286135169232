import React, { Fragment } from 'react'
import RenderRichText from './renderRichText'
import { Link } from 'gatsby'
import LoadMore from '../components/loadMore'

const CaseStudies = ({data}) => {
  return (
    <LoadMore data={data} showMore={3} showItems={6}>
      {({currentItems}) => (
        <Fragment>
          { currentItems.map((item, count) => 
            <div className={`col-sm-6 col-lg-4 mb-4 item case-study ${item.categories ? item.categories : ''}`} key={count}>
              <Link to={'/case-studies/' + item.slug}>
                <img className="img-fluid" src={ item.featuredImage.fixed.src || item.featuredImage.fluid.src} alt={ item.featuredImage.title }/>
              </Link>
              <div className="case-study-meta">
                <p className={`classification ${ item.type  ? 'Case Study' : 'Article' }`}>{ item.type  ? 'Case Study' : 'Article' }</p>
                <p className="date">{ item.date }</p>
                <div className="clear"></div>
                <Link to={'/case-studies/' + item.slug}><h3 className="name">{ item.entryTitle }</h3></Link>
                <span className="description"><RenderRichText data={item.content.json} /></span>
                <Link className="action" to={'/case-studies/' + item.slug}>Read More</Link>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </LoadMore>
  )
}

export default CaseStudies