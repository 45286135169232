import React, { Component, Fragment } from 'react'

class LoadMore extends Component {
  constructor(props) {
    super(props)
    
    this.handleShowMore = this.handleShowMore.bind(this)
    
    this.state = {
      items: this.props.data,
      showItems: this.props.showItems,
      showMore: this.props.showMore,
      currentItems: []
    }
  }

  /*static getDerivedStateFromProps(props, state) {
    console.log('changed')
    if(props.data !== state.items) {
      return {
        items: props.data 
      }
    }
  }*/

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    const items = nextProps.data.slice(0, this.state.showItems);
    if (nextProps.data !== this.props.data) {
      this.setState({
        currentItems: items
      });
    }
  }

  componentDidMount() {
    if(this.state.items.length > 0) {
      this.setState({
        currentItems: this.state.items.slice(0, this.state.showItems)
      })
    }
  }
  
  handleShowMore() {
    this.setState({
      showItems: this.state.showItems >= this.state.items.length ? this.state.showItems : this.state.showItems + this.state.showMore,
      currentItems: this.state.items.slice(0, this.state.showItems + this.state.showMore)
    })
  }

  renderShowMore() {
    if(this.state.currentItems.length !== this.state.items.length) {
      return (
        <div className="text-center button-holder">
          <button className="btn btn-primary" onClick={this.handleShowMore}>Load More</button>
        </div>
      )
    }
  }
  
  render() {  
    return (
      <Fragment>
        { this.props.children({
          ...this.props,
          ...this.state
        })}
        { this.props.data.length ? this.renderShowMore() : <div>No Results</div>}
      </Fragment>
    )
  }
}
  
export default LoadMore