import React from "react";
import { BLOCKS, INLINES, } from "@contentful/rich-text-types";
import { documentToReactComponents, next } from "@contentful/rich-text-react-renderer";
import { Image } from 'react-bootstrap'
import InlineSingleCallOut from './contentTypes/inlineSingleCallOut'


let link;

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if('fields' in node.data.target) {     
        const image = node.data.target.fields.file && node.data.target.fields.file['en-US'];
        return link ? <a href={link} target="_blank"><Image src={image.url} /></a> : <Image src={image.url} />
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      return <InlineSingleCallOut data={node.data.target.fields} />
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={`${node.data.uri}`} target={`${node.data.uri.startsWith('https://sdsportsacu.com') ? '_self' : '_blank' }`}>{children}</a>;
    }
  }
}

const RenderRichText = ({data, imgLink}) => {
  link = imgLink
  return documentToReactComponents(data, options)
}

export default RenderRichText;